import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/gitlab-runner/builds/_fryZbSQ/0/adnan.begoli/is-microfinance/packages/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "analitike"
    }}>{`Analitike`}</h1>
    <p>{`Analitike dolaze iz Google Analytics rješenja (opšte informacije o posjetiocima, uređajima, gradovima, procenat završenosti koraka)
, HotJar (snimke korištenja i mape kretanja korisinka), i direktno iz web zahtjev aplikacije iz koje se mogu po potrebi pripremiti razni analitički podaci.`}</p>
    <h2 {...{
      "id": "google-analytics"
    }}>{`Google Analytics`}</h2>
    <p><a parentName="p" {...{
        "href": "https://analytics.google.com/"
      }}>{`Google Analytics`}</a>{` nudi opšti uvid o broju posjetilaca, koliko dugo provode na stranici, koliko brzo odustanu. Te dodatne informacije o kanalu preko kojeg su dospjeli do zahtjeva, demografiji korisnika, vrsti uređaja itd.`}</p>
    <p>{`Osim toga moguće je podesiti namjenske analize koje prate stepen završenosti forme, okidače na proizvoljne akcije (npr. klik na Kontakt, ili upload CRK dokumenta, koliko često je klijent bio neaktivan / vidio formu za neaktivnost), i niz drugih stvari.`}</p>
    <p>{`###TODO Funnel analysis i slike ekrana/primjeri kada sakupimo podataka`}</p>
    <h2 {...{
      "id": "hotjar"
    }}>{`Hotjar`}</h2>
    <p><a parentName="p" {...{
        "href": "https://insights.hotjar.com/"
      }}>{`Hotjar`}</a>{` nudi Heatmap vizualizacije koje vam pokažu gdje korisnik najčešće klikne, dokle scroll-a, i kretanja po stranici.`}</p>
    <p>{`Nudi snimke korisničke aktivnosti, koje su ovdje realizovane za`}</p>
    <ul>
      <li parentName="ul">{`Korak 1 (informacije o kreditu)`}</li>
      <li parentName="ul">{`Korak 2 (osnovne informacije)`}</li>
      <li parentName="ul">{`Korak 4 (dokumentacija)`}</li>
    </ul>
    <p>{`Osim toga, moguće je da se dodaju ankete i feedback komponente.`}</p>
    <h3 {...{
      "id": "heatmap"
    }}>{`Heatmap`}</h3>
    <p><img alt="alt text" src={require("./assets/heatmap.png")} /></p>
    <h3 {...{
      "id": "snimanje-korisnika"
    }}>{`Snimanje korisnika`}</h3>
    <p><img alt="alt text" src={require("./assets/recording-list.png")} /></p>
    <p><img alt="alt text" src={require("./assets/recording.png")} /></p>
    <h2 {...{
      "id": "admin-dashboard"
    }}>{`Admin dashboard`}</h2>
    <p>{`Sadrži informacije o vremenima reakcije radnika, i o stepenu završenosti forme.`}</p>
    <h3 {...{
      "id": "todo-screenshots"
    }}>{`TODO screenshots`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      